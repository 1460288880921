import axios from '@/common/axios'
import qs from 'qs'

export function addEquipmentUse (data) {
  return axios({
    method: 'post',
    url: '/universal/equipmentUse/add',
    data: qs.stringify(data)
  })
}

export function deleteEquipmentUse (id) {
  return axios({
    method: 'delete',
    url: '/universal/equipmentUse/delete/' + id
  })
}

export function updateEquipmentUse (data) {
  return axios({
    method: 'put',
    url: '/universal/equipmentUse/update',
    data: qs.stringify(data)
  })
}

export function selectEquipmentUseInfo (id) {
  return axios({
    method: 'get',
    url: '/universal/equipmentUse/info/' + id
  })
}

export function selectEquipmentUseList (query) {
  return axios({
    method: 'get',
    url: '/universal/equipmentUse/list',
    params: query
  })
}
