<template>
  <div id="equipmentUse">
    <el-dialog
      :title="equipmentUseFormTitle"
      width="1200px"
      :visible.sync="equipmentUseDialogVisible"
      :close-on-click-modal="false"
      @close="equipmentUseDialogClose"
    >
      <el-form
        ref="equipmentUseFormRef"
        :model="equipmentUseForm"
        :rules="equipmentUseFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="设备编号" prop="equipmentNo">
              <el-select
                v-model="equipmentUseForm.equipmentNo"
                placeholder="请选择设备"
                clearable
                @change="devChange"
              >
                <el-option
                  v-for="item in devList"
                  :key="item.id"
                  :label="item.deviceNo"
                  :value="item.deviceNo"
                >
                  <span style="float: left">{{ item.deviceNo }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{ item.deviceName }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="设备名称" prop="equipmentName">
              <el-input v-model="equipmentUseForm.equipmentName" placeholder="请输入设备名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="年度" prop="yearDate">
              <el-input v-model="equipmentUseForm.yearDate" placeholder="请输入年度" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="月份" prop="monthDate">
              <el-input v-model="equipmentUseForm.monthDate" placeholder="请输入月份" clearable />
            </el-form-item>
          </el-col>
          <!-- <el-col :span="8">
            <el-form-item label="操作人" prop="operator">
              <el-input v-model="equipmentUseForm.operator" placeholder="请输入操作人" clearable />
            </el-form-item>
          </el-col> -->
        </el-row>
        <el-form-item>
          <vxe-toolbar
            v-if="equipmentUseFormTitle === '新增设备使用、维护保养记录'
              || equipmentUseFormTitle === '修改设备使用、维护保养记录'"
          >
            <template #buttons>
              <vxe-button @click="insertRowEvent()">
                新增
              </vxe-button>
            </template>
          </vxe-toolbar>
          <vxe-table
            ref="xTable"
            border
            resizable
            show-overflow
            keep-source
            :data="detailList"
            :edit-config="{trigger: 'click', mode: 'cell'}"
          >
            <vxe-table-column
              field="recordDate"
              title="日期"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-colgroup title="使用时间" header-align="center">
              <vxe-table-column
                field="startTime"
                title="开始时间"
                :edit-render="{name: '$input', props: {clearable: true}}"
              />
              <vxe-table-column
                field="endTime"
                title="结束时间"
                :edit-render="{name: '$input', props: {clearable: true}}"
              />
            </vxe-table-colgroup>
            <vxe-table-colgroup title="运行情况" header-align="center">
              <vxe-table-column field="useBefore" title="使用前" width="150">
                <template v-slot="{row,column}">
                  <vxe-radio-group v-model="row[column.property]">
                    <vxe-radio :label="1" content="正常" />
                    <vxe-radio :label="2" content="异常" />
                  </vxe-radio-group>
                </template>
              </vxe-table-column>
              <vxe-table-column field="useAfter" title="使用后" width="150">
                <template v-slot="{row,column}">
                  <vxe-radio-group v-model="row[column.property]">
                    <vxe-radio :label="1" content="正常" />
                    <vxe-radio :label="2" content="异常" />
                  </vxe-radio-group>
                </template>
              </vxe-table-column>
            </vxe-table-colgroup>
            <vxe-table-column field="maintenanceState" title="维护保养" width="120">
              <template v-slot="{row,column}">
                <vxe-radio-group v-model="row[column.property]">
                  <vxe-radio :label="1" content="是" />
                  <vxe-radio :label="2" content="否" />
                </vxe-radio-group>
              </template>
            </vxe-table-column>
            <vxe-table-column
              field="remarks"
              title="备注"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="operator"
              title="操作人"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="caseRegistration"
              title="情况登记"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              v-if="equipmentUseFormTitle === '新增设备使用、维护保养记录'
                || equipmentUseFormTitle === '修改设备使用、维护保养记录'"
              title="操作"
              width="100"
            >
              <template #default="{ row }">
                <template>
                  <vxe-button @click="removeRowEvent(row)">
                    删除
                  </vxe-button>
                </template>
              </template>
            </vxe-table-column>
          </vxe-table>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="equipmentUseDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="equipmentUseFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="设备名称">
        <el-input v-model="searchForm.equipmentName" placeholder="请输入设备名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="equipmentUsePage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="equipmentName" label="设备名称" />
      <el-table-column prop="equipmentNo" label="设备编号" />
      <el-table-column prop="yearDate" label="年度" />
      <el-table-column prop="monthDate" label="月份" />
      <!-- <el-table-column prop="operator" label="操作人" /> -->
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="equipmentUsePage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addEquipmentUse,
  deleteEquipmentUse,
  updateEquipmentUse,
  selectEquipmentUseInfo,
  selectEquipmentUseList
} from '@/api/universal/equipmentUse'
import { selectBaseInfoList } from '@/api/device/baseInfo'

export default {
  data () {
    return {
      equipmentUseDialogVisible: false,
      equipmentUseFormTitle: '',
      equipmentUseForm: {
        id: '',
        equipmentName: '',
        equipmentNo: '',
        yearDate: '',
        monthDate: '',
        // operator: '',
        detailJson: ''
      },
      equipmentUseFormRules: {
        equipmentName: [{ required: true, message: '设备名称不能为空', trigger: ['blur', 'change']}]
      },
      equipmentUsePage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        equipmentName: ''
      },
      devList: [],
      detailList: []
    }
  },
  created () {
    selectEquipmentUseList(this.searchForm).then(res => {
      this.equipmentUsePage = res
    })
    selectBaseInfoList().then(res => {
      this.devList = res.list
    })
  },
  methods: {
    equipmentUseDialogClose () {
      this.$refs.equipmentUseFormRef.resetFields()
      this.detailList = []
    },
    equipmentUseFormSubmit () {
      if (this.equipmentUseFormTitle === '设备使用、维护保养记录详情') {
        this.equipmentUseDialogVisible = false
        return
      }
      this.$refs.equipmentUseFormRef.validate(async valid => {
        if (valid) {
          this.equipmentUseForm.detailJson = JSON.stringify(this.$refs.xTable.getTableData().tableData)
          if (this.equipmentUseFormTitle === '新增设备使用、维护保养记录') {
            await addEquipmentUse(this.equipmentUseForm)
          } else if (this.equipmentUseFormTitle === '修改设备使用、维护保养记录') {
            await updateEquipmentUse(this.equipmentUseForm)
          }
          this.equipmentUsePage = await selectEquipmentUseList(this.searchForm)
          this.equipmentUseDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.equipmentUseFormTitle = '新增设备使用、维护保养记录'
      this.equipmentUseDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteEquipmentUse(row.id)
        if (this.equipmentUsePage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.equipmentUsePage = await selectEquipmentUseList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.equipmentUseFormTitle = '修改设备使用、维护保养记录'
      this.equipmentUseDialogVisible = true
      this.selectEquipmentUseInfoById(row.id)
    },
    handleInfo (index, row) {
      this.equipmentUseFormTitle = '设备使用、维护保养记录详情'
      this.equipmentUseDialogVisible = true
      this.selectEquipmentUseInfoById(row.id)
    },
    selectEquipmentUseInfoById (id) {
      selectEquipmentUseInfo(id).then(res => {
        this.equipmentUseForm.id = res.id
        this.equipmentUseForm.equipmentName = res.equipmentName
        this.equipmentUseForm.equipmentNo = res.equipmentNo
        this.equipmentUseForm.yearDate = res.yearDate
        this.equipmentUseForm.monthDate = res.monthDate
        // this.equipmentUseForm.operator = res.operator
        this.detailList = res.detailList
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectEquipmentUseList(this.searchForm).then(res => {
        this.equipmentUsePage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectEquipmentUseList(this.searchForm).then(res => {
        this.equipmentUsePage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectEquipmentUseList(this.searchForm).then(res => {
        this.equipmentUsePage = res
      })
    },
    devChange (value) {
      if (value) {
        let dev = this.devList.find(item => item.deviceNo === value)
        this.equipmentUseForm.equipmentName = dev.deviceName
      } else {
        this.equipmentUseForm.equipmentName = ''
      }
    },
    async insertRowEvent (row) {
      let { row: newRow } = await this.$refs.xTable.insertAt({}, -1)
      await this.$refs.xTable.setActiveRow(newRow)
    },
    removeRowEvent (row) {
      this.$refs.xTable.remove(row)
    }
  }
}
</script>

<style>
</style>
